<template>
<div class="container mx-auto">
  <div class="bg-white dark:bg-gray-900 rounded-lg shadow p-6">
  <div class="text-4xl sm:text-5xl md:text-6xl font-semibold mb-10 mt-2 text-center dark:text-white heading">matchheft.ch</div>

 <p v-if="!getBooklets.length" class="text-gray-500 mt-2 text-sm">{{$t('booklets.no_data')}}</p>
 <div class="flex flex-col sm:flex-row sm:flex-wrap gap-4 sm:justify-center">
    <div v-for="book in getBooklets" :key="book.id">
      <div class="flex flex-col w-full sm:w-72 bg-gray-300 dark:bg-gray-700 dark:text-white p-4 rounded-lg shadow-md pt-1 pb-2 text-sm">
        <div class="flex justify-between">
          <div class="flex flex-col w-full">
            <div class="flex justify-between w-full mb-2 place-items-start pt-3">
              <div class="font-semibold">{{book.league}}<br>{{formatDate(book.game_datetime)}}</div>
              <div class="flex">
                <a href="#" @click="openPdf(book)" class="flex items-center p-1 text-gray-800 dark:text-white hover:text-white dark:hover:text-gray-400">
                 <svg class="fill-current hover:text-white h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11.363 2c4.155 0 2.637 6 2.637 6s6-1.65 6 2.457v11.543h-16v-20h7.363zm.826-2h-10.189v24h20v-14.386c0-2.391-6.648-9.614-9.811-9.614zm4.811 13h-2.628v3.686h.907v-1.472h1.49v-.732h-1.49v-.698h1.721v-.784zm-4.9 0h-1.599v3.686h1.599c.537 0 .961-.181 1.262-.535.555-.658.587-2.034-.062-2.692-.298-.3-.712-.459-1.2-.459zm-.692.783h.496c.473 0 .802.173.915.644.064.267.077.679-.021.948-.128.351-.381.528-.754.528h-.637v-2.12zm-2.74-.783h-1.668v3.686h.907v-1.277h.761c.619 0 1.064-.277 1.224-.763.095-.291.095-.597 0-.885-.16-.484-.606-.761-1.224-.761zm-.761.732h.546c.235 0 .467.028.576.228.067.123.067.366 0 .489-.109.199-.341.227-.576.227h-.546v-.944z"/></svg>
                </a>
                <a href="#" @click="openQrCodePdf(book)" class="flex items-center p-1 text-gray-800 dark:text-white hover:text-white dark:hover:text-gray-400">
                  <svg class="fill-current hover:text-white h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 2c1.654 0 3 1.346 3 3v14c0 1.654-1.346 3-3 3h-14c-1.654 0-3-1.346-3-3v-14c0-1.654 1.346-3 3-3h14zm0-2h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-8 8h-1v-2h1v1h2v1h-1v1h-1v-1zm2 12v-1h-1v1h1zm-1-15v-1h-2v1h1v1h1v-1zm8-1v6h-1v-1h-4v-5h5zm-1 4v-3h-3v3h3zm-14 2h-1v1h2v-1h-1zm0 3h1v1h1v-3h-1v1h-2v2h1v-1zm5 1v2h1v-2h-1zm4-10h-1v3h1v-3zm0 5v-1h-1v1h1zm3-2h1v-1h-1v1zm-10-1h-1v1h1v-1zm2-2v5h-5v-5h5zm-1 1h-3v3h3v-3zm9 5v1h-1v-1h-2v1h-1v-1h-3v-1h-1v1h-1v1h1v2h1v-1h1v2h1v-2h3v1h-2v1h2v1h1v-3h1v1h1v2h1v-1h1v-1h-1v-1h-1v-1h1v-1h-2zm-11 8h1v-1h-1v1zm-2-3h5v5h-5v-5zm1 4h3v-3h-3v3zm12-3v-1h-1v1h1zm0 1h-1v1h-1v-1h-1v-1h1v-1h-2v-1h-1v2h-1v1h-1v3h1v-1h1v-1h2v2h1v-1h1v1h2v-1h1v-1h-2v-1zm-9-3h1v-1h-1v1zm10 2v1h1v1h1v-3h-1v1h-1zm2 4v-1h-1v1h1zm0-8v-1h-1v1h1z"/></svg>
                </a>
              </div>
            </div>
            <div>{{book.home_team}}</div>
            <div>{{book.away_team}}</div>
          </div>
        </div>
        <div class="flex items-center justify-between mt-1 mb-2">
          <div class="text-xs mt-1 text-gray-500 dark:text-gray-600">{{updatedAt(book)}}</div>
        </div>
      </div>
    </div>
 </div>
  </div>
  <Footer class="fixed bottom-0 left-0"></Footer>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ApiClient from '@/assets/js/ApiClient.js'
import fileDownload from 'js-file-download'
import dayjs from 'dayjs'
import dateMixin from '@/mixin/dateMixin.js'
import Tooltip from 'codex-tooltip'
import Footer from '../components/Footer.vue'

export default {
  mixins: [dateMixin],
  components: {
    Footer
  },
  data () {
    return {
      tooltip: new Tooltip()
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      isLoggedIn: 'isLoggedIn',
      bookletsPublishedAll: 'swissunihockey/bookletsPublishedAll',
      gameById: 'swissunihockey/gameById',
      booklet: 'swissunihockey/booklet'
    }),
    getBooklets () {
      const format = 'YYYY-MM-DD HH:mm:ss'
      const result = this.bookletsPublishedAll
      return result.sort((a, b) => dayjs(b.game_datetime, format) - dayjs(a.game_datetime, format))
    }
  },
  methods: {
    openPdf (booklet) {
      this.isOpen = false
      ApiClient.get(`/api/su/booklets/${booklet.id}/download?typ=pdf`, { responseType: 'blob', headers: { Accept: 'application/pdf' } }).then(res => {
        const filename = `${this.formatDateDownload(booklet.game_datetime)}_vs_${booklet.away_team.replace(/ /g, '')}.pdf`
        fileDownload(res.data, filename)
      })
    },
    openQrCodePdf (booklet) {
      this.isOpen = false
      ApiClient.get(`/api/su/booklets/${booklet.id}/download?typ=qrcode_pdf`, { responseType: 'blob' }).then(res => {
        const filename = `QrCode_${this.formatDateDownload(booklet.game_datetime)}_vs_${booklet.away_team.replace(/ /g, '')}.pdf`
        fileDownload(res.data, filename)
      })
    },
    updatedAt (booklet) {
      return dayjs(booklet.blocks[0].time).format('DD.MM.YYYY HH:mm')
    },
    isModified (booklet) {
      return booklet.published && dayjs(booklet.blocks[0].time).isAfter(booklet.updated_at)
    },
    loadBooklet (booklet) {
      if (this.isLoggedIn) {
        this.$store.dispatch('swissunihockey/updateGame', this.gameById(booklet.su_game_id))
        this.$emit('onUpdateMatchheft', booklet.blocks)
      }
    }
  },
  mounted () {
    if (this.$refs.published) {
      this.$refs.published.forEach(pub => {
        this.tooltip.onHover(pub, this.$t('published'), {
          placement: 'top'
        })
      })
    }
    if (this.$refs.warning) {
      this.$refs.warning.forEach(pub => {
        this.tooltip.onHover(pub, this.$t('warning_booklet_modified'), {
          placement: 'top'
        })
      })
    }
  }
}

</script>
